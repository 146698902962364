import { Box, Button, Container, Flex, Link, Text, VStack } from "@chakra-ui/react";
import React from "react";

import { MotionBox, TwoGridHero } from "../../components";
import ConfettiScroll from "../../components/scroll/Confetti";

export default function Contact(): JSX.Element {
    return (
        <Box as="section" bg="gray.700" id="contact">
            <MotionBox bg="gray.700">
                <Container
                    minHeight="100vh"
                    height="100%"
                    px={{
                        base: "4",
                        md: "8",
                    }}
                    pt={{
                        base: "6",
                        md: "8",
                    }}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    maxW="container.xl"
                    color="white"
                >
                    <TwoGridHero
                        title="CONTACT"
                        subtitle="We're here! Our door is always open for a good cup of coffee."
                        desc="Looking for help with a project? Any question, concern or ideas to share with us? Get in touch with us anytime and we’ll happily answer your questions!"
                    />
                    <Flex
                        pt={{ base: "16", md: "24" }}
                        gap="10"
                        direction={{ base: "column", md: "row" }}
                        align="center"
                        w="full"
                    >
                        <VStack flex="1" w="full" h="full" spacing="2" alignItems="flex-start" pb="12">
                            <Text fontWeight="bold" fontSize="md">
                                Address
                            </Text>

                            <Text fontSize="md" fontWeight="light">
                                Mehmetçik Mh. Aspendos Blv. No:99, 07300 Orema A.Ş. Muratpaşa/Antalya Turkey
                            </Text>
                            <Text fontSize="md" fontWeight="light">
                                Phone:
                                <Link href="tel:+90 (242) 504 15 37"> +90 (242) 504 15 37</Link>
                            </Text>
                        </VStack>
                        <Box
                            display="flex"
                            flex="1"
                            h="full"
                            w="full"
                            justifyContent="flex-end"
                            fontWeight="light"
                            fontSize="sm"
                        >
                            <Link
                                w={{ base: "full", sm: "full", md: "230px" }}
                                h="40px"
                                href="mailto:contact@orema.com.tr"
                                style={{ textDecoration: "none" }}
                            >
                                <Button
                                    bg="gray.700"
                                    border="1px"
                                    borderColor="white"
                                    fontWeight="sm"
                                    _hover={{ bg: "graySpecial.600" }}
                                    _active={{ bg: "graySpecial.600" }}
                                    _focus={{ bg: "graySpecial.600" }}
                                    isFullWidth
                                >
                                    contact@orema.com.tr
                                </Button>
                            </Link>
                        </Box>
                    </Flex>
                    <ConfettiScroll />
                </Container>
            </MotionBox>
        </Box>
    );
}
