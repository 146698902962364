import { Box, Flex } from "@chakra-ui/react";
import React, { ReactNode } from "react";

type Props = {
    leftBox: ReactNode;
    rightBox: ReactNode;
};

export default function GridHero({ leftBox, rightBox }: Props): JSX.Element {
    return (
        <Flex direction={{ base: "column", lg: "row" }} gap="12" w="full" color="white">
            <Box flex="1">{leftBox}</Box>
            <Box flex="1">{rightBox}</Box>
        </Flex>
    );
}
