import {Box, Container, Flex, Image, Link, Text} from "@chakra-ui/react";
import React from "react";
import {BsArrowRight} from "react-icons/bs";

import OfficeWork from "../../assets/images/office/office_2.png";
import {Company, MotionBox, TwoGridHero} from "../../components";

export default function Careers(): JSX.Element {
    return (
        <Box as="section" bgGradient={["linear(to-b, primary.500, secondary.500)"]} id="careers" className="section">
            <MotionBox>
                <Container
                    minHeight="100vh"
                    height="100%"
                    px={{
                        md: "8",
                    }}
                    pt={{
                        base: "12",
                        md: "16",
                    }}
                    maxW="container.xl"
                    color="white"
                >
                    <Box px={{ base: "4" }}>
                        <TwoGridHero
                            title="CAREERS"
                            subtitle="Be part of our family."
                            desc="Problems are our opportunities. Play with the codes to solve real-world problems."
                        />
                        <Image src={OfficeWork} w="100%" py="24" borderRadius="md" h={{ base: "full", md: "full" }} />
                    </Box>
                    <Flex direction="column" bg="primary.600" borderRadius="md">
                        <Box pt="10" pl={{ base: "4", md: "4" }} color="primary.50">
                            <Text
                                fontSize={{ base: "4xl", md: "5xl" }}
                                lineHeight={{ base: "10", md: "normal" }}
                                maxW="xl"
                            >
                                Get to know the list of perks{" "}
                                <Box as="span" fontWeight="bold">
                                    Orema
                                </Box>{" "}
                                gathered to make you happy.
                            </Text>
                        </Box>
                        <Company />
                    </Flex>
                    <Flex px={{ base: "2", md: "0" }} justify="space-between" align="center" pt="24" pb="36">
                        <Box w={{ base: "75%", md: "100%" }}>
                            <Text fontSize="3xl" fontWeight="light">
                                Can’t find an open position that suits you?
                            </Text>
                            <Text pt="2" color="primary.100" fontSize="sm">
                                Shoot us an e-mail to apply for a role that’s not readily available.
                            </Text>
                        </Box>

                        <Link href="mailto:contact@orema.com.tr" target="_blank">
                            <BsArrowRight size="44" />
                        </Link>
                    </Flex>
                {/*    <Scroll href="contact" />*/}
                </Container>
            </MotionBox>
        </Box>
    );
}
