import { SimpleGrid, Stack } from "@chakra-ui/react";
import React from "react";
import { BiCheckCircle, BiLockAlt, BiSmile } from "react-icons/bi";
import { IoBalloonOutline, IoIceCreamOutline, IoShapesOutline } from "react-icons/io5";

import Feature from "./Feature";
import FeatureTitle from "./FeatureTitle";

const features = [
    {
        title: "we do the right thing",
        icon: BiCheckCircle,
        description:
            " We believe that the best ideas can come from anywhere. Our job is to seek out those ideas, to shape and improve them through kind argument, and to take them from concept to action.",
    },
    {
        title: "privacy",
        icon: BiLockAlt,
        description:
            "It is strictly forbidden to share projects, ideas or data within the company with third parties. Everything is protected by privacy agreements at Orema Software.",
    },
    {
        title: "keep together",
        icon: IoShapesOutline,
        description:
            "We act like owners. We seek out problems and we solve them. We help each other and those who matter to us. We finish what we start and we make projects to last. And when we make mistakes, we’ll own up to them.",
    },
    {
        title: "happiness",
        icon: BiSmile,
        description:
            "Creating a positive workplace where employees feel empowered with the right tools and resources to perform at their best is critical to finding happiness at work. That's what we are doing!",
    },
    {
        title: "always be honest",
        icon: IoBalloonOutline,
        description:
            "We treat our clients as our friends. We treat the software industry with respect. So, we must always be honest with each other.",
    },
    {
        title: "create wow!",
        icon: IoIceCreamOutline,
        description:
            "Everything we do - how we act, treat our users, and how we build our product - should be aimed at creating WOW moments for our users.",
    },
];

export default function Features() {
    return (
        <Stack as="section" spacing="16">
            <FeatureTitle />
            <SimpleGrid columns={{ sm: 2, md: 2, lg: 3 }} spacing={{ base: 2, md: 4 }}>
                {features.map((feature, index: React.Key) => (
                    <Feature key={index} title={feature.title} icon={feature.icon}>
                        {feature.description}
                    </Feature>
                ))}
            </SimpleGrid>
        </Stack>
    );
}
