import React from "react";
import { Box, Button, Container, Flex, Image, Link, Text, VStack } from "@chakra-ui/react";
import AhtapotLiveWhite from "../../assets/images/products/ahtapot-live_white.svg";
import ProductImage from "../../assets/images/product-banners/ahtapot-live-banner.png";
import {BackHome, Scroll} from "../../components";

export default function AhtapotLivePage(): JSX.Element {
    return (
        <Box bg="fuschia.500" color="white">
            <BackHome />
            <Container
                px={{ base: "6", md: "24" }}
                pb={{
                    base: "8",
                }}
                pt={{
                    base: "0",
                }}
                maxW="container.xl"
            >
                <VStack
                    py={{
                        base: "24",
                        md: "36",
                    }}
                >
                    <Link href="https://ahtapot.live/">
                        <Image
                            color="white"
                            w={{ base: "150px", md: "200px" }}
                            src={AhtapotLiveWhite}
                            alt="ahtapot.live"
                        />
                    </Link>
                    <Text fontSize="3xl" fontWeight="light" textAlign="center">
                        Reach, engage, and grow your community with online events
                    </Text>
                </VStack>

                <Flex
                    p={{ base: 4, md: 6 }}
                    borderRadius="md"
                    bg="fuschia.900"
                    direction={{ base: "column", sm: "row", md: "row" }}
                    fontSize="sm"
                    justify="space-between"
                    gap="4"
                >
                    <Flex direction="column">
                        <Text color="#FFFFFFA3">Industry</Text>
                        <Text>Webinar, Video Conferencing, Meetings </Text>
                    </Flex>

                    <Flex direction="column">
                        <Text color="#FFFFFFA3">Platforms</Text>
                        <Text>Web</Text>
                    </Flex>
                    <Flex direction="column">
                        <Text color="#FFFFFFA3">Technologies</Text>
                        <Text> Quarkus, React, AWS, MongoDB</Text>
                    </Flex>
                    <Flex direction="column">
                        <Text color="#FFFFFFA3">Year</Text>
                        <Text>2021</Text>
                    </Flex>
                </Flex>
                <Image src={ProductImage} w="100%" pt="2" borderTopRadius="md" />
                <VStack
                    spacing="12"
                    bg="fuschia.900"
                    pt="2"
                    px={{ base: "4", sm: "6", md: "12", lg: "16", xl: "24" }}
                    pb="12"
                    fontWeight="light"
                    lineHeight="1.1"
                    borderBottomRadius="md"
                >
                    <VStack direction="column" mt="10" gap="2" align="flex-start" fontSize="sm">
                        <Text fontSize="md" lineHeight="1.5">
                            Ahtapot.live makes webinars easier and more accessible for you. It establish your brand
                            awareness by allowing you to get maximum efficiency from the online events that you organize
                            with your target audience, potential customers or colleagues. By providing you with all the
                            marketing and communication tools you need for an effective webinar, it allows you to get
                            the best results that can be recieve.
                        </Text>
                    </VStack>
                    <VStack direction="column" mt="10" gap="2" align="flex-start" fontSize="sm">
                        <Text fontSize="md" fontWeight="semibold">
                            High quality, economic, simple
                        </Text>
                        <Text fontSize="md" lineHeight="1.5">
                            Online events serve many purposes that will benefit you and your organization. Webinars are
                            used efficiently in all areas from corporate life to education and business. Also,
                            one-to-one interaction with current and potential customers is great for a business.
                        </Text>
                        <Text fontSize="md" lineHeight="1.5">
                            Ahtapot.live is designed to be easily used by everyone. You can use the application in
                            Turkish or English. It has a simple interface so it is easy to use. With our cutting edge
                            ahtapot.live application you can organize online events through your browser without any
                            problems. In this way your attendees will not have to download any applications.
                        </Text>
                        <Text fontSize="md" lineHeight="1.5">
                            Plus, you can customize screen designs completely as you wish for your own company/brand.
                            You can also add logo and background, change the theme color. If you wish, you can organize
                            your events under your own domain name (meeting.companydomain.com).
                        </Text>
                    </VStack>
                    <VStack direction="column" mt="10" gap="2" align="flex-start" w="100%">
                        <Text fontSize="md" fontWeight="semibold">
                            Discover more!
                        </Text>
                        <Text fontSize="md" lineHeight="1.5">
                            Verify your phone number get $10 credits.
                        </Text>
                    </VStack>
                    <Flex align="center" justify="center" w="full" pt={{ base: 4, md: 8 }}>
                        <Link h="40px" href="https://ahtapot.live/" target="_blank" style={{ textDecoration: "none" }}>
                            <Button
                                bg="fuschia.600"
                                color="white"
                                border="1px"
                                borderColor="#FFFFFFA3"
                                fontWeight="sm"
                                _hover={{ bg: "fuschia.900" }}
                                _active={{ bg: "fuschia.900" }}
                                _focus={{ bg: "fuschia.900" }}
                            >
                                Try it free
                            </Button>
                        </Link>
                    </Flex>
                </VStack>
            </Container>

            <Scroll href="/#work" status={false} />
        </Box>
    );
}
