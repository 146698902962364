import { Box, type BoxProps } from "@chakra-ui/layout";
import { motion } from "framer-motion";
import { ReactNode } from "react";

export const MBox = motion<BoxProps>(Box);

const DEFAULT_VARIANTS = {
    in: {
        opacity: 1,
    },
    initial: {
        transition: {
            duration: 1.5,
            ease: [0.87, 0, 0.13, 1],
        },
    },
    out: {
        opacity: 0,
    },
};

const DEFAULT_TRANSITION = {
    in: {
        opacity: 1,
    },
    out: {
        opacity: 0,
    },
    duration: 1,
};

type Props = {
    transition?: any;
    variants?: any;
    children: ReactNode;
    bgGradient?: string | Array<string>;
    bg?: string;
    height?: string;
    overflow?: string;
    animate?: any;
    zIndex?: any;
};

export default function MotionBox({
    transition,
    variants,
    children,
    bgGradient,
    bg,
    height,
    overflow,
    zIndex,
    animate,
}: Props): JSX.Element {
    return (
        <MBox
            zIndex={zIndex}
            height={height || "100%"}
            bgGradient={bgGradient}
            bg={bg}
            overflow={overflow}
            variants={variants || DEFAULT_VARIANTS}
            transition={transition || DEFAULT_TRANSITION}
            initial="out"
            animate={"in" || animate}
            exit="out"
        >
            {children}
        </MBox>
    );
}
