const JobPostData = [
    {
        id: "1",
        title: "Backend Developer",
        subtitle: "Node.js, Java",
        typeOfEmployments: ["Full time", "Flex, sched", "Mid lvl"],
        responsibilities: "",
        description: [
            "We are an accomplished product team on the verge of promising international growth. We work with global brands and dedicate teams to projects according to their needs.",
            "Business offers are literally raining down on us, but before jumping on to them, we would like to keep our work culture as it is. To control this growth with a human-centric approach, we need experienced people.",
            "You can view our backend development stack from this link. Get a clearer idea of how we approach the projects we develop from the podcast here, and you can have a glance at our Instagram profile.",
        ],
        requirements: [
            "2+ years experience with Cloud providers such as AWS, GCP, Azure Experience with version",
            "Control system such as Github Experience with container tools such as Containerd, Docker",
            "Experience with container orchestration such as Kubernetes, Openshift Experience with",
            "Cloud-native applications Experience in preparing CI/CD pipelines Experience with",
            "monitoring and logging tools Bonus points if you have experience with Bash, Go Bonus",
            "points if you have experience with Microservices architecture",
        ],
        techs: [
            "We don’t solely lean on libraries or frameworks in software development but the overall sustainability, reliability, and purity of the code itself.",
            "We like to think about abstractions such as Separation of Concerns, layered architecture, and IoC rather than just “getting things done”.",
            "If you are willing to talk about these, then we can confidently say that we are on the same page.",
        ],
    },
    {
        id: "2",
        title: "Frontend Developer",
        subtitle: "React.js, Redux",
        typeOfEmployments: ["Full time", "Flex, sched", "Mid lvl"],
        responsibilities: "",
        description: [
            "We are an accomplished product team on the verge of promising international growth. We work with global brands and dedicate teams to projects according to their needs.",
            "Business offers are literally raining down on us, but before jumping on to them, we would like to keep our work culture as it is. To control this growth with a human-centric approach, we need experienced people.",
            "You can view our backend development stack from this link. Get a clearer idea of how we approach the projects we develop from the podcast here, and you can have a glance at our Instagram profile.",
        ],
        requirements: [
            "2+ years experience with Cloud providers such as AWS, GCP, Azure Experience with version",
            "Control system such as Github Experience with container tools such as Containerd, Docker",
            "Experience with container orchestration such as Kubernetes, Openshift Experience with",
            "Cloud-native applications Experience in preparing CI/CD pipelines Experience with",
            "monitoring and logging tools Bonus points if you have experience with Bash, Go Bonus",
            "points if you have experience with Microservices architecture",
        ],
        techs: [
            "We don’t solely lean on libraries or frameworks in software development but the overall sustainability, reliability, and purity of the code itself.",
            "We like to think about abstractions such as Separation of Concerns, layered architecture, and IoC rather than just “getting things done”.",
            "If you are willing to talk about these, then we can confidently say that we are on the same page.",
        ],
    },
    {
        id: "3",
        title: "Mobile Application Developer",
        subtitle: "Flutter, Dart",
        typeOfEmployments: ["Full time", "Senior lvl"],
        responsibilities: "",
        description: [
            "We are an accomplished product team on the verge of promising international growth. We work with global brands and dedicate teams to projects according to their needs.",
            "Business offers are literally raining down on us, but before jumping on to them, we would like to keep our work culture as it is. To control this growth with a human-centric approach, we need experienced people.",
            "You can view our backend development stack from this link. Get a clearer idea of how we approach the projects we develop from the podcast here, and you can have a glance at our Instagram profile.",
        ],
        requirements: [
            "2+ years experience with Cloud providers such as AWS, GCP, Azure Experience with version",
            "Control system such as Github Experience with container tools such as Containerd, Docker",
            "Experience with container orchestration such as Kubernetes, Openshift Experience with",
            "Cloud-native applications Experience in preparing CI/CD pipelines Experience with",
            "monitoring and logging tools Bonus points if you have experience with Bash, Go Bonus",
            "points if you have experience with Microservices architecture",
        ],
        techs: [
            "We don’t solely lean on libraries or frameworks in software development but the overall sustainability, reliability, and purity of the code itself.",
            "We like to think about abstractions such as Separation of Concerns, layered architecture, and IoC rather than just “getting things done”.",
            "If you are willing to talk about these, then we can confidently say that we are on the same page.",
        ],
    },
    {
        id: "4",
        title: "UI/UX Designer",
        subtitle: "Figma, Adobe XD",
        typeOfEmployments: ["Full time", "Part time", "Junior lvl"],
        responsibilities: "",
        description: [
            "We are an accomplished product team on the verge of promising international growth. We work with global brands and dedicate teams to projects according to their needs.",
            "Business offers are literally raining down on us, but before jumping on to them, we would like to keep our work culture as it is. To control this growth with a human-centric approach, we need experienced people.",
            "You can view our backend development stack from this link. Get a clearer idea of how we approach the projects we develop from the podcast here, and you can have a glance at our Instagram profile.",
        ],
        requirements: [
            "2+ years experience with Cloud providers such as AWS, GCP, Azure Experience with version",
            "Control system such as Github Experience with container tools such as Containerd, Docker",
            "Experience with container orchestration such as Kubernetes, Openshift Experience with",
            "Cloud-native applications Experience in preparing CI/CD pipelines Experience with",
            "monitoring and logging tools Bonus points if you have experience with Bash, Go Bonus",
            "points if you have experience with Microservices architecture",
        ],
        techs: [
            "We don’t solely lean on libraries or frameworks in software development but the overall sustainability, reliability, and purity of the code itself.",
            "We like to think about abstractions such as Separation of Concerns, layered architecture, and IoC rather than just “getting things done”.",
            "If you are willing to talk about these, then we can confidently say that we are on the same page.",
        ],
    },
];

export default JobPostData;
