import React from "react";
import { Box, Container, Flex, Image, Text, VStack } from "@chakra-ui/react";
import ProductImage from "../../assets/images/product-banners/metamax-banner.png";
import {BackHome, Scroll} from "../../components";
import Metamax from "../../assets/images/products/metamax.svg";

export default function MetamaxPage(): JSX.Element {
    return (
        <Box bg="gray.700" color="white">
            <BackHome />
            <Container
                px={{ base: "6", md: "12" }}
                pb={{
                    base: "8",
                }}
                pt={{
                    base: "0",
                }}
                maxW="container.xl"
            >
                <VStack
                    py={{
                        base: "24",
                        md: "36",
                    }}
                >
                    <Image color="white" w={{ base: "150px", md: "200px" }} src={Metamax} alt="metamax" />
                    <Text fontSize="3xl" fontWeight="light" textAlign="center">
                        The easy way to invest in real estate
                    </Text>
                </VStack>

                <Flex
                    px={{ base: 4, md: 6 }}
                    py={{ base: 2, md: 4 }}
                    borderRadius="md"
                    bg="gray.600"
                    direction={{ base: "column", sm: "row", md: "row" }}
                    justify="space-between"
                    gap="4"
                >
                    <Flex direction="column">
                        <Text color="#FFFFFFA3">Industry</Text>
                        <Text>Blockchain, NFT, Estate</Text>
                    </Flex>

                    <Flex direction="column">
                        <Text color="#FFFFFFA3">Platforms</Text>
                        <Text>Web</Text>
                    </Flex>
                    <Flex direction="column">
                        <Text color="#FFFFFFA3">Technologies</Text>
                        <Text>TypeScript, NodeJS, React, Redux,</Text>
                        <Text>PostgreSQL, Redis, AWS, Chakra UI, Docker</Text>
                    </Flex>
                    <Flex direction="column">
                        <Text color="#FFFFFFA3">Year</Text>
                        <Text>2022</Text>
                    </Flex>
                </Flex>
                <Image src={ProductImage} w="100%" pt="2" borderTopRadius="md" />
                <VStack
                    spacing="12"
                    bg="gray.600"
                    pt="2"
                    px={{ base: "4", sm: "6", md: "12", lg: "16", xl: "24" }}
                    pb="12"
                    fontWeight="light"
                    lineHeight="1.1"
                    borderBottomRadius="md"
                >
                    <VStack direction="column" mt="10" gap="2" align="flex-start">
                        <Text fontWeight="semibold" fontSize="md">
                            What is Metamax?
                        </Text>
                        <Text color="primary.100" fontSize="md" lineHeight="1.5">
                            Metamax converts real estate into digital assets (NFTs) with the power of blockchain,
                            allowing you to invest in partial real estate shares with minimal budgets. Within the
                            system, the investor owns a part of the real estate, while he/she makes a profit as much as
                            his/her share at the rate of increase in the value of the entire property.
                        </Text>
                        <Text color="primary.100" fontSize="md" lineHeight="1.5">
                            With Metamax, high entry-cost real estate investment becomes accessible to a wide investor
                            base. In this way, the ability of a small investor and a large investor to use the same
                            investment tool ensures equality of opportunity in investment.
                        </Text>
                    </VStack>

                    <VStack direction="column" mt="10" gap="2" align="flex-start">
                        <Text fontWeight="semibold" fontSize="md">
                            Blockchain Technology
                        </Text>
                        <Text color="primary.100" fontSize="md" lineHeight="1.5">
                            Blockchain technology is a transparent and reliable system in which data is processed in a
                            distributed way and protected by strong encrypted records. Trust in the system, which is
                            supported by smart contracts, is achieved not thanks to the relationship between the buyer
                            and the seller intermediary, but thanks to technology in the network.
                        </Text>
                    </VStack>
                    <VStack direction="column" mt="10" gap="2" align="flex-start">
                        <Text fontWeight="semibold" fontSize="md">
                            Renting
                        </Text>
                        <Text color="primary.100" fontSize="md" lineHeight="1.5">
                            Real estate within the system is rentable property. The rent on the rentable property is
                            transferred to the investor's wallet on a daily basis at the rate of his/her share. In this
                            way, the rental income from the property is distributed fairly to the shareholders by us
                            from the day the shares are received.
                        </Text>
                    </VStack>
                    <VStack direction="column" mt="10" gap="2" align="flex-start">
                        <Text fontWeight="semibold" fontSize="md">
                            Pre-Sale & Market
                        </Text>
                        <Text color="primary.100" fontSize="md" lineHeight="1.5">
                            Real estate is first listed on pre-sale at bottom prices. In this way, the investor becomes
                            the owner of shares at very reasonable prices and can sell the shares he/she owns at the
                            market 24/7 at the price he/she wants. At the same time, the investor can differentiate
                            his/her investment by buying real estate NFTs that have been sold in the market. One of the
                            goals of the system is that investors can expand their investment portfolio and buy shares
                            in high-demand real estate in the system.
                        </Text>
                    </VStack>
                </VStack>
            </Container>

            <Scroll href="/work" status={false} />
        </Box>
    );
}
