import { Box, Text, VStack } from "@chakra-ui/react";
import React from "react";

export default function FeatureTitle() {
    return (
        <VStack align="start" textAlign={{ base: "center", md: "start" }}>
            <Text fontSize={{ base: "4xl", md: "5xl" }} lineHeight={{ base: "10", md: "normal" }} maxW={{ lg: "md" }}>
                Values &{" "}
                <Box as="span" fontWeight="bold">
                    Principles
                </Box>
            </Text>
        </VStack>
    );
}
