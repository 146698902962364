import { Text } from "@chakra-ui/react";
import React from "react";
import { useLocation } from "react-router-dom";
import AhtapotLivePage from "./AhtapotLivePage";
import AhtapotChatPage from "./AhtapotChatPage";
import MetamaxPage from "./MetamaxPage";

export default function ProductSingle(): JSX.Element {
    const location = useLocation();
    if (location.pathname.split("/")[2] === "ahtapot-live") {
        return <AhtapotLivePage />;
    }
    if (location.pathname.split("/")[2] === "ahtapot-chat") {
        return <AhtapotChatPage />;
    }
    if (location.pathname.split("/")[2] === "metamax") {
        return <MetamaxPage />;
    }
    return <Text>Error</Text>;
}
