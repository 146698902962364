import { Box, Button, Container, Flex, FormControl, Grid, GridItem, Input, Text, Textarea } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { Dropzone, Share } from "../../components";
import JobPostData from "../../components/job-post/JobPostData";

export default function Join(): JSX.Element {
    const { id } = useParams();
    const [post, setPost] = React.useState<any>();

    function getPost() {
        const newPost = JobPostData.find((p) => p.id === id);
        setPost(newPost);
    }

    useEffect(() => {
        getPost();

        //eslint-disable-next-line
    }, [id]);

    return (
        <Box bgGradient="linear(to-b, primary.500, secondary.500)">
            <Box
                w="100%"
                h="100%"
                color="white"
                px={{ base: "2", md: "12", lg: "16", xl: "24" }}
                py={{ sm: "12", base: "16", md: "20", lg: "24", xl: "36" }}
                position="relative"
            >
                <Container maxW="container.xl">
                    <Flex justify="space-between" pt="10">
                        <Flex pb="6" direction={{ base: "column", md: "row" }}>
                            <Text fontWeight="semibold" color="primary.50">
                                Job openings {">"}
                            </Text>
                            <Text fontWeight="semibold" pl={{ base: "0", md: "2" }} color="primary.50">
                                {post?.title} {">"}
                            </Text>
                            <Text fontWeight="semibold" pl={{ base: "0", md: "2" }}>
                                Application
                            </Text>
                        </Flex>
                        <Share />
                    </Flex>

                    <Flex w="100%" direction="column">
                        <Text fontSize="md" fontWeight="semibold" pt="12">
                            Personal Information
                        </Text>
                        <Text fontSize="sm" pb="6">
                            Tell us something about yourself
                        </Text>
                    </Flex>
                    <Grid
                        templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
                        gap={6}
                        bg="rose"
                        color="primary.500"
                    >
                        <GridItem w="100%" h="100%">
                            <FormControl>
                                <Input id="name" type="name" bg="white" placeholder="Full name" />
                            </FormControl>
                        </GridItem>
                        <GridItem w="100%" h="100%">
                            <FormControl>
                                <Input id="email" type="email" bg="white" placeholder="Phone number" />
                            </FormControl>
                        </GridItem>
                        <GridItem w="100%" h="100%">
                            <FormControl>
                                <Input id="email" type="email" bg="white" placeholder="Email address" />
                            </FormControl>
                        </GridItem>
                        <GridItem w="100%" h="100%">
                            <FormControl>
                                <Input id="headline" type="name" bg="white" placeholder="Headline" />
                            </FormControl>
                        </GridItem>
                    </Grid>
                    <Flex direction="column" h="100%" gap="6" pb="24">
                        <Flex direction="column" pt="12">
                            <Text fontSize="md" fontWeight="semibold">
                                CV / Resume
                            </Text>
                            <Text fontSize="xs">Tell us something about yourself</Text>
                        </Flex>
                        <Box>
                            <FormControl id="file">
                                <Dropzone />
                            </FormControl>
                            <Box py="12">
                                <Box w="100%" bg="white" borderRadius="md">
                                    <Textarea placeholder="Cover letter (Optional)" h="150px" color="primary.500" />
                                </Box>
                            </Box>
                        </Box>
                        <Box>
                            <Button
                                w="100%"
                                bg="fuschia.100"
                                _hover={{ bg: "fuschia.900" }}
                                _active={{ bg: "fuschia.900" }}
                                _focus={{ bg: "fuschia.900 " }}
                            >
                                Submit
                            </Button>
                        </Box>
                    </Flex>
                </Container>
            </Box>
        </Box>
    );
}
