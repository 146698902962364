import { Flex, Text, VStack } from "@chakra-ui/react";
import React from "react";

type TwoGridHeroProps = {
    title: string;
    subtitle: string;
    desc: string;
};

export default function TwoGridHero({ title, subtitle, desc }: TwoGridHeroProps): JSX.Element {
    return (
        <Flex pt="24" gap="10" direction={{ base: "column", md: "row" }} align="center">
            <VStack w="full" h="full" spacing="2" alignItems="flex-start">
                <Text fontSize="xs">{title}</Text>

                <Text fontSize="3xl" pr="20" fontWeight="light">
                    {subtitle}
                </Text>
            </VStack>
            <Text
                w="full"
                h="full"
                fontSize={{ base: "sm", sm: "md", md: "lg" }}
                fontWeight="light"
                pl={{ base: "0", md: "3" }}
            >
                {desc}
            </Text>
        </Flex>
    );
}
