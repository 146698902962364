import React from 'react';
import {Flex,Text} from "@chakra-ui/react";
import {IoMdArrowBack} from "react-icons/io";
import {useNavigate} from "react-router-dom";

export default function BackHome(): JSX.Element {
    const navigate = useNavigate();
return (
        <Flex alignItems="center" pl="6" pt="12" justify="start" gap="2" onClick={() =>navigate(-1)}>
            <IoMdArrowBack size={30}/>
            <Text fontSize="24" fontWeight="semi-bold">
                Home
            </Text>
        </Flex>
    );
}
