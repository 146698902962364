import { Box, Flex,  VStack } from "@chakra-ui/react";
import { motion } from "framer-motion";
import React from "react";
// @ts-ignore
import { Link } from "react-scroll";
import {RiArrowDownSLine} from "react-icons/ri";
import {useLocation,useNavigate} from "react-router-dom";
import {useState,useEffect} from "react";

type Props = {
    href: string;
    position?: any;
    status?: boolean;
};

const MotionBox = motion(Box);

const mouseStyle = {
    display: "block",
    width: "30px",
    height: "30px",
    borderRadius: "0.5rem",
};

const bounceTransition = {
    y: {
        duration: 2.5,
        repeatType: Infinity,
        ease: "easeInOut",
        repeat: Infinity,
        repeatDelay: 1,
    },
};

export default function Scroll({ href, position = "relative", status = true }: Props): JSX.Element {
    let location = useLocation();
    const [isProduct, setIsProduct] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        if (location.pathname.includes("/work")) {
            setIsProduct(true);
        }
        if(location.pathname === "/"){
            setIsProduct(false);
        }
    }, [location.pathname]);

    return (
        <Flex w="full" justifyContent="center">
            {isProduct ?(
                <Link
                    onClick={()=>{navigate(-1)}}
                    spy
                    smooth
                    to={href}
                    _hover={{ textDecoration: "none" }}
                    _focus={{ outline: "none" }}

                >
                    <VStack
                        justify="center"
                        py={{ base: "12", md: "12", xl: "12" }}
                        position={position}
                        mx="0"
                        bottom="0"
                        left="0"
                        right="0"
                        color="white"
                    >
                        <MotionBox
                            cursor="pointer"
                            style={mouseStyle}
                            transition={bounceTransition}
                            animate={{
                                y: ["-5px", "-30px", "-5px"],
                            }}
                        >
                            <RiArrowDownSLine size="35" />
                        </MotionBox>

                    </VStack>
                </Link>
                ):
                (<Link to={href} _hover={{ textDecoration: "none" }} _focus={{ outline: "none" }}
                       smooth spy
                >
                    <VStack
                        justify="center"
                        py={{ base: "12", md: "12", xl: "12" }}
                        position={position}
                        mx="0"
                        bottom="0"
                        left="0"
                        right="0"
                        color="white"
                    >
                        <MotionBox
                            cursor="pointer"
                            style={mouseStyle}
                            transition={bounceTransition}
                            animate={{
                                y: ["-5px", "-30px", "-5px"],
                            }}
                        >
                            <RiArrowDownSLine size="35" />
                        </MotionBox>

                    </VStack>
                </Link>)
            }
        </Flex>
    );
}
