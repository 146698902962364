import { Box, Icon, Stack, Text } from "@chakra-ui/react";
import React from "react";

type FeatureProps = {
    icon: any;
    title: string;
    children: React.ReactNode;
};
export default function Feature({ title, children, icon }: FeatureProps): JSX.Element {
    return (
        <Box
            p={{ base: "4", md: "6" }}
            borderRadius="8"
            _hover={{
                boxShadow: "rgba(255, 255, 255, 0.05) 0px 6px 24px 0px, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px",
                bg: "#ffffff0d",
            }}
        >
            <Icon w="8" h="8" as={icon} />
            <Stack mt="4">
                <Text as="h3" fontSize="xl" fontWeight="semibold">
                    {title}
                </Text>
                <Text fontWeight="light">{children}</Text>
            </Stack>
        </Box>
    );
}
