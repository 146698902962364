import { Grid } from "@chakra-ui/react";
import React from "react";

import AhtapotChat from "../../assets/images/products/ahtapot-chat.svg";
import AhtapotLive from "../../assets/images/products/ahtapot-live_pink.svg";
import Metamax from "../../assets/images/products/metamax.svg";
import ProductItem from "./ProductItem";

export default function Product(): JSX.Element {
    return (
        <Grid
            templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
            gap="10"
            w="100%"
            h="100%"
            pt="32"
            pb={{ base: "32", md: "12" }}
            fontSize="sm"
            fontWeight="light"
        >
            <ProductItem
                title="Metamax"
                description="The easy way to invest in real estate."
                image={Metamax}
                to="/work/metamax"
                colSpan={2}
            />
            <ProductItem
                title="Ahtapot.live"
                description="Reach, engage, and grow your community with online events!"
                image={AhtapotLive}
                to="/work/ahtapot-live"
                colSpan={1}
            />
            <ProductItem
                title="Ahtapot.chat"
                description="You can make video calls with your team or friends anywhere and  anytime."
                image={AhtapotChat}
                to="/work/ahtapot-chat"
                colSpan={1}
            />
        </Grid>
    );
}
