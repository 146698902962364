import { Center, Flex, Img, SimpleGrid, Stack } from "@chakra-ui/react";
import React from "react";

import OfficeImage from "../../../assets/images/office/office.png";
import VisionCard from "./VisionCard";
import VisionTitle from "./VisionTitle";

export default function Visions(): JSX.Element {
    return (
        <Flex gap="12" align="center">
            <Stack flex="2" spacing="8">
                <VisionTitle />
                <SimpleGrid columns={{ base: 1, md: 3, lg: 2, xl: 3 }} spacing={{ base: 3, md: 6 }} fontWeight="light">
                    <VisionCard
                        title="do it better"
                        description="Question if the established solutions are up to our standards and try to find better solutions."
                    />
                    <VisionCard
                        title="aim for the greater good"
                        description="The important thing about what we do is what we actually do, not who did it."
                    />
                    <VisionCard
                        title="continuously improve"
                        description="As enthusiasts, we must always be on the lookout for ways to improve ourselves."
                    />
                </SimpleGrid>
            </Stack>
            <Center flex="1" display={{ base: "none", lg: "flex" }}>
                <Img src={OfficeImage} alt="Office" borderRadius="10px"/>
            </Center>
        </Flex>
    );
}
