export const Opportunity = [
    {
        subtitle: "Opening doors to success",
        desc: "We support your personal and professional development with challenging projects and regular feedback.",
    },
    {
        subtitle: "Enhance your skills and academic knowledge",
        desc: "In our company, it is especially important to encourage people to improve their skills with academic knowledge.",
    },
    {
        subtitle: "Funny teammates",
        desc: "Social room in the office, lunch, snacks and drinks... All yours! Enjoy it with your funny teammates.",
    },
    {
        subtitle: "Competitive salary",
        desc: "We know what that make you valuable and we are always ready to reward you for your success.",
    },
];

export const Responsibility = [
    {
        subtitle: "Sharpen your skills",
        desc: "The opportunity to improve your skills with software and development.",
    },
    {
        subtitle: "Your ideas matter",
        desc: "We know that you have an idea inside of you. You may even have several ideas. We are here to tell you that your ideas matter.",
    },
    {
        subtitle: "Taking responsibility",
        desc: "Career starts with taking responsibility for our thoughts, choices, and reactions, only then can we be responsible for the circumstances that create our careers.",
    },
    {
        subtitle: "Try & learn",
        desc: "No one needs to tell you anything. Don't afraid to make mistake. The real mistake is to stop trying.",
    },
];

export const Culture = [
    {
        subtitle: "Talent perceives differences",
        desc: "Our strength lies in our differences. We always respect your talents and who you are.",
    },
    {
        subtitle: "Open-mindedness in workplace",
        desc: "There are many ups and downs in business and only an open-minded person can go through all the difficulty whilst keeping their mind clear and cool.",
    },
    {
        subtitle: "Patience",
        desc: "The most important thing for us is patience. Cause there is always bugs/defects. Remember, every problem is an opportunity in R&D.",
    },
    {
        subtitle: "Focus on a common goal",
        desc: "Working toward common goals is one of the defining characteristics of our team.",
    },
];
