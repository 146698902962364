import { Center, Flex, Image, SimpleGrid } from "@chakra-ui/react";
import React from "react";

import TechnologyTitle from "./TechnologyTitle";

const technologies = [
    { name: "git", image: "techs/git.svg" },
    { name: "node", image: "techs/node.svg" },
    { name: "python", image: "techs/python.svg" },
    { name: "npm", image: "techs/npm.svg" },
    { name: "jb", image: "techs/jb.svg" },
    { name: "ts", image: "techs/ts.svg" },
    { name: "postgresql", image: "techs/postgresql.svg" },
    { name: "redis", image: "techs/redis.svg" },
    { name: "activemq", image: "techs/activemq.svg" },
    { name: "chakraui", image: "techs/chakraui.svg" },
    { name: "js", image: "techs/js.svg" },
    { name: "java", image: "techs/java.svg" },
    { name: "react", image: "techs/react.svg" },
    { name: "redmine", image: "techs/redmine.svg" },
    { name: "redux", image: "techs/redux.svg" },
    { name: "quarkus", image: "techs/quarkus.svg" },
    { name: "mongo", image: "techs/mongo.svg" },
    { name: "aws", image: "techs/aws.svg" },
    { name: "sass", image: "techs/sass.svg" },
    { name: "antdesign", image: "techs/antdesign.svg" },
];

export default function Technologies(): JSX.Element {
    return (
        <Flex w="100%" direction="column" gap="12">
            <TechnologyTitle />
            <SimpleGrid
                columns={{ base: 4, sm: 5, lg: 10 }}
                w="100%"
                bg="#371D6B52"
                borderRadius="md"
                px={{ base: "2", sm: "6", md: "8" }}
                py={{ base: "4", md: "6" }}
            >
                {technologies.map((tech, index: React.Key) => (
                    <Center key={index} p={{ base: "3", md: "6" }}>
                        <Image
                            width="8"
                            height="8"
                            src={require(`../../assets/images/${tech.image}`)}
                            alt={tech.name}
                        />
                    </Center>
                ))}
            </SimpleGrid>
        </Flex>
    );
}
