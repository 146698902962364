import { SimpleGrid } from "@chakra-ui/react";
import * as React from "react";

import Member from "./Member";

const members = [
    {
        title: "Frontend Developer",
        image: "/members/aysegul-kavakli.jpg",
        name: "Ayşegül Kavaklı",
        url: "https://www.linkedin.com/in/aysegulkavakli/",
    },
    {
        title: "Software Developer",
        image: "/members/beyza-erfidan.jpg",
        name: "Beyza Erfidan",
        url: "https://www.linkedin.com/in/beyza-erfidan/",
    },
    {
        title: "Accounting Manager",
        image: "/members/celaleddin-ibis.jpg",
        name: "Celaleddin İbiş",
        url: "https://www.linkedin.com/in/celaleddin-ibi%C5%9F/",
    },
    {
        title: "Office Assistant",
        image: "/members/elif-canbolat.jpg",
        name: "Elif Canbolat",
        url: "",
    },
    {
        title: "CEO & Co-Founder",
        image: "/members/ender-kazan.jpg",
        name: "Ender Kazan",
        url: "https://www.linkedin.com/in/ender-kazan-91205215/",
    },
    {
        title: "Accounting Assistant Specialist",
        image: "/members/esra-kolaklar.jpg",
        name: "Esra Kolaklar",
        url: "https://www.linkedin.com/in/esra-kolaklar/",
    },
    {
        title: "AI Developer Intern",
        image: "/members/ezgi-sena-anadollu.jpg",
        name: "Ezgi Sena Anadollu",
        url: "https://www.linkedin.com/in/ezgi-sena-anadollu/",
    },
    {
        title: "Financial Advisor",
        image: "/members/fatih-ozturk.jpg",
        name: "Fatih Öztürk",
        url: "https://www.linkedin.com/in/fatih-%C3%B6zt%C3%BCrk-bb097a42/",
    },
    {
        title: "COO & Co-Founder",
        image: "/members/huseyin-emre-kazan.jpg",
        name: "Hüseyin Emre Kazan",
        url: "https://www.linkedin.com/in/emrekazan/",
    },
    {
        title: "Software Test Specialist",
        image: "/members/mahmutcan-inci.jpg",
        name: "Mahmut Can İnci",
        url: "https://www.linkedin.com/in/mahmut-can-inci-391195201/",
    },
    {
        title: "UI / UX Designer",
        image: "/members/melike-atalay.jpg",
        name: "Melike Atalay",
        url: " https://www.linkedin.com/in/melike-atalay-957758235/",
    },
    {
        title: "Blockchain Developer",
        image: "/members/murat-odunc.jpg",
        name: "Murat Ödünç",
        url: "https://www.linkedin.com/in/mu-od/",
    },
    {
        title: "General Coordinator",
        image: "/members/salih-zeki-aksoy.jpg",
        name: "Salih Zeki Aksoy",
        url: ""
    },
    {
        title: "IT Manager",
        image: "/members/serdar-baspinar.jpg",
        name: "Serdar Başpınar",
        url: "https://www.linkedin.com/in/serdar-ba%C5%9Fp%C4%B1nar-770347241/",
    },
    {
        title: "CTO & Co-Founder",
        image: "/members/sertac-anadollu.jpg",
        name: "Sertaç Anadollu",
        url: "https://www.linkedin.com/in/sertacanadollu/",
    },
    {
        title: "QA Developer",
        image: "/members/yigitcan-teke.jpg",
        name: "Yiğit Can Teke",
        url: "https://www.linkedin.com/in/yigitcanteke/",
    },
    {
        title: "Lawyer",
        image: "/members/zeliha-milli.jpg",
        name: "Zeliha Milli",
        url: "https://www.linkedin.com/in/zeliha-milli-889071159/",
    },
    {
        title: "Embedded Design Engineer",
        image: "/members/zeynel-deniz-hasirci.jpg",
        name: "Zeynel Deniz Hasırcı",
        url: "https://www.linkedin.com/in/zdh-in/",
    },
    {
        title: "Project Coordinator",
        image: "/members/zubeyde-sari.jpg",
        name: "Zübeyde Sarı",
        url: "https://www.linkedin.com/in/z%C3%BCbeyde-sari-pmp%C2%AE-executive-mba-2b390841/",
    },
];

export default function Members(): JSX.Element {
    return (
        <SimpleGrid columns={{ base: 2, md: 3, lg: 4 }} gap={{ base: 4, md: 8 }}>
            {members.map((member, index) => (
                <Member
                    key={index}
                    name={member.name}
                    title={member.title}
                    image={require(`../../assets/images${member.image}`)}
                />
            ))}
        </SimpleGrid>
    );
}
