import { Box, Center, GridItem, Image, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

type ProductProps = {
    title: string;
    description: string;
    image: string;
    to: string;
    colSpan: number;
};
export default function ProductItem({ to, title, description, image, colSpan }: ProductProps): JSX.Element {
    return (
        <GridItem colSpan={{ base: 1, md: colSpan }}>
            <VStack spacing="2" align="flex-start">
                <Link style={{ width: "100%" }} to={to}>
                    <Center
                        h="220px"
                        w="100%"
                        bg="primary.600"
                        borderRadius="md"
                        _hover={{ cursor: "pointer", bg: "primary.700" }}
                    >
                        <Image w={{ base: 150, md: 200 }} src={image} alt="Ahtapot Live" />
                    </Center>
                </Link>
                <Box>
                    <Text fontWeight="semibold">{title}</Text>
                    <Text> {description}</Text>
                </Box>
            </VStack>
        </GridItem>
    );
}
