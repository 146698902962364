import { Button, Link, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import React from "react";
import { BsShareFill } from "react-icons/bs";
import UseAnimations from "react-useanimations";
import facebook from "react-useanimations/lib/facebook";
import linkedin from "react-useanimations/lib/linkedin";
import twitter from "react-useanimations/lib/twitter";

export default function Share(): JSX.Element {
    return (
        <Menu>
            <MenuButton
                as={Button}
                rightIcon={<BsShareFill />}
                bg="transparent"
                _hover={{ bg: "transparent" }}
                _active={{ bg: "transparent " }}
                _focus={{ bg: "transparent " }}
            >
                Share this job
            </MenuButton>

            <MenuList color="black">
                <Link href="https://www.facebook.com/sharer/sharer.php?u=" style={{ textDecoration: " none" }}>
                    <MenuItem>
                        <UseAnimations animation={facebook} />
                        Facebook
                    </MenuItem>
                </Link>
                <Link
                    href="https://www.linkedin.com/shareArticle?mini=true&url=&title=&summary=&source="
                    style={{ textDecoration: " none" }}
                >
                    <MenuItem>
                        <UseAnimations animation={linkedin} />
                        linkedin
                    </MenuItem>
                </Link>
                <Link href="https://twitter.com/intent/tweet?text=" style={{ textDecoration: " none" }}>
                    <MenuItem>
                        <UseAnimations animation={twitter} />
                        twitter
                    </MenuItem>
                </Link>
            </MenuList>
        </Menu>
    );
}
