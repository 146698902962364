import { Box, Image, Text, VStack } from "@chakra-ui/react";
import React from "react";

import Community from "../../assets/images/community/community.png";
import { GridHero } from "../../components";

export default function AboutGrid(): JSX.Element {
    const image = <Image src={Community} alt="Community" borderRadius="md" />;
    const description = (
        <VStack justify="center" h="full" fontWeight="light" alignItems="flex-start">
            <Text fontSize={{ base: "4xl", md: "5xl" }} textAlign="start" lineHeight={{ base: "10", md: "normal" }}>
                Join our{" "}
                <Box as="span" fontWeight="bold">
                    community
                </Box>
            </Text>
            <Text>
                Are you an engineer and interested in improving your skills and abilities? Our team has both hardware
                and software engineers. Bring your passion, your talent and drive software&apos;s innovation across with
                us. Let&apos;s build the next big thing together!
            </Text>
        </VStack>
    );
    return <GridHero leftBox={image} rightBox={description} />;
}
