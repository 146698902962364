import {
    Box,
    Collapse,
    Flex,
    HStack,
    IconButton,
    Image,
    Link as CLink,
    Stack,
    useDisclosure,
    VStack
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { CgClose, CgMenuGridR } from "react-icons/cg";
import "../../App.scss";
import Logo from "../../assets/images/logo/logo.svg";
import { useLocation } from "react-router-dom";
// @ts-ignore
import { Link } from "react-scroll";

type HeaderProps = {
    bgColor?: string;
    status?: boolean;
};

export default function Header({ bgColor, status = false }: HeaderProps) {
    const location = useLocation();
    const { isOpen, onToggle } = useDisclosure();
    const [navVisible, setNavVisible] = useState(false);
    const [headerPathname, setHeaderPathname] = useState(false);
    const [router, setRouter] = useState("");

    useEffect(() => {

        if (location.hash === "#/" || location.hash === "/" || location.hash === "") {
            setRouter("");
            setNavVisible(false);
            setHeaderPathname(false);
        } else {
            setNavVisible(true);
            setRouter(location.hash);
            setHeaderPathname(true);

        }
        if (
            location.pathname === "/work/metamax" ||
            location.pathname === "/work/ahtapot-live" ||
            location.pathname === "/work/ahtapot-chat"
        ) {
            setNavVisible(true);
        }
    }, [location.hash, headerPathname, location.pathname]);

    window.onscroll = function () {
        scrollFunction()

    };

    function scrollFunction() {
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
            setNavVisible(true);
        } else {
            setNavVisible(false);
        }
    }

    return (
        <Stack
            id="header-menu"
            position="fixed"
            top="0"
            left="0"
            zIndex="10"
            w="full"
            color="white"
            bg={bgColor || "transparent"}
            className={`${navVisible || headerPathname ? "scroll-navbar" : ""} ${isOpen ? "active-collapse" : ""}`}
        >
            <Flex justify="space-between" p={{ base: "4", md: "6" }}>
                <Box pr="4">
                    <CLink href="/" _focus={{ outline: "none" }}

                    >
                        <Image src={Logo} alt="Orema Logo" />
                    </CLink>
                </Box>
                <HStack
                    as="nav"
                    spacing={{
                        sm: "4",
                        base: "6",
                        md: "8",
                        lg: "12",
                        xl: "20",
                    }}
                    className="nav-link"
                    display={{ base: "none", md: "flex" }}
                    fontWeight="medium"
                >
                    <Link
                        to="about"
                        activeClass="active" smooth spy
                        _hover={{ textDecoration: "none" }}
                        data-menuanchor="About"
                        href={status ? "/#about" : "#about"}
                    >
                        About
                    </Link>
                    <Link
                        to="work"
                        activeClass="active" smooth spy
                        _hover={{ textDecoration: "none" }}
                        data-menuanchor="Work"
                        href={status ? "/#work" : "#work"}
                        className={router.slice(1) === "Work".toLowerCase() ? "active link" : "inactive link"}
                    >
                        Work
                    </Link>
                    <Link
                        to="careers"
                        activeClass="active" smooth spy
                        _hover={{ textDecoration: "none" }}
                        data-menuanchor="Careers"
                        href={status ? "/#careers" : "#careers"}
                        className={router.slice(1) === "Careers".toLowerCase() ? "active link" : "inactive link"}
                    >
                        Careers
                    </Link>
                    <Link
                        to="contact"
                        smooth spy
                        activeClass="active"
                        _hover={{ textDecoration: "none" }}
                        data-menuanchor="Contact"
                        href={status ? "/#contact" : "#contact"}
                        className={router.slice(1) === "Contact".toLowerCase() ? "active link" : "inactive link"}
                    >
                        Contact
                    </Link>
                </HStack>
                <HStack as="nav" display={{ base: "flex", md: "none" }} fontWeight="medium">
                    <IconButton
                        onClick={onToggle}
                        _hover={{ bgColor: "none", outline: "none", boxShadow: "none" }}
                        _active={{ bgColor: "none", outline: "none", boxShadow: "none" }}
                        icon={
                            isOpen ? (
                                <CgClose style={{ width: "24", height: "24" }} />
                            ) : (
                                <CgMenuGridR style={{ width: "24", height: "24" }} />
                            )
                        }
                        variant="ghost"
                        aria-label="Toggle Navigation"
                    />
                </HStack>
            </Flex>
            <Collapse
                in={isOpen}
                animateOpacity
                style={{
                    marginTop: "0!important",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "100vh",
                }}
            >
                <Stack spacing={4}>
                    <VStack
                        spacing={16}
                        p={18}
                        justify="space-between"
                        flexDir="column"
                        align="center"
                        className="nav-link"
                        as="nav"
                        fontWeight="medium"
                        _hover={{
                            textDecoration: "none",
                        }}
                    >
                        <Link
                            onClick={onToggle}
                            to="about"
                            smooth spy
                            activeClass="active"
                            _hover={{ textDecoration: "none" }}
                            data-menuanchor="About"
                            href={status ? "/#about" : "#about"}
                            className={router.slice(1) === "About".toLowerCase() ? "active" : "inactive"}
                        >
                            About
                        </Link>
                        <Link
                            onClick={onToggle}
                            on
                            to="work"
                            smooth spy
                            activeClass="active"
                            _hover={{ textDecoration: "none" }}
                            data-menuanchor="Work"
                            href={status ? "/#work" : "#work"}
                            className={router.slice(1) === "Work".toLowerCase() ? "active" : "inactive"}
                        >
                            Work
                        </Link>
                        <Link
                            onClick={onToggle}
                            to="careers"
                            smooth spy
                            activeClass="active"
                            _hover={{ textDecoration: "none" }}
                            data-menuanchor="Careers"
                            href={status ? "/#careers" : "#careers"}
                            className={router.slice(1) === "Careers".toLowerCase() ? "active" : "inactive"}
                        >
                            Careers
                        </Link>
                        <Link
                            onClick={onToggle}
                            to="contact"
                            smooth spy
                            activeClass="active"
                            _hover={{ textDecoration: "none" }}
                            data-menuanchor="Contact"
                            href={status ? "/#contact" : "#contact"}
                            className={router.slice(1) === "Contact".toLowerCase() ? "active" : "inactive"}
                        >
                            Contact
                        </Link>
                    </VStack>
                </Stack>
            </Collapse>
        </Stack>
    );
}
