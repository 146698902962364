import "./App.scss";
import { isMobile } from "react-device-detect";
import { AnimatePresence, motion, useMotionValue, useSpring } from "framer-motion";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";

import { Header, ScrollToTop } from "./components";
import { CareerDetails, Landing, Join, ProductSingle } from "./pages";
import React, { useEffect, useState } from "react";
import CookieBanner from "./components/cookie-banner/CookieBanner";

export function App(): JSX.Element {
    const location = useLocation();
    const cursorX = useMotionValue(100);
    const cursorY = useMotionValue(100);

    const springConfig = { damping: 9999, stiffness: 99999999 };
    const cursorXSpring = useSpring(cursorX, springConfig);
    const cursorYSpring = useSpring(cursorY, springConfig);

    const [statusPathname, setStatusPathname] = useState(false);

    useEffect(() => {
        if (
            location.pathname === "/work/metamax" ||
            location.pathname === "/work/ahtapot-live" ||
            location.pathname === "/work/ahtapot-chat"
        ) {
            setStatusPathname(true);
        } else {
            setStatusPathname(false);
        }
    }, [location.pathname]);

    useEffect(() => {
        const moveCursor = (e: MouseEvent) => {
            cursorX.set(e.clientX);
            cursorY.set(e.clientY);
        };

        let loadingElement: any = document.getElementById("loading");
        loadingElement.style.cssText = "display: none";

        window.addEventListener("mousemove", moveCursor);

        return () => {
            loadingElement = undefined;
            window.removeEventListener("mousemove", moveCursor);
        };
    }, [cursorX, cursorY]);

    return (
        <>
            <AnimatePresence>
                {!isMobile && (
                    <motion.div
                        key="mobileCursorComponent"
                        className="cursor"
                        style={{
                            zIndex: 99999,
                            translateX: cursorXSpring,
                            translateY: cursorYSpring,
                        }}
                    />
                )}
                {!statusPathname && (<Header key="headerComponent" status={statusPathname} />)}
                <ScrollToTop key="scrollToTopComponent">
                    <Routes location={location} key={location.pathname}>
                        <Route path="/" element={<Landing />} />
                        <Route path="/work/ahtapot-live" element={<ProductSingle />} />
                        <Route path="/work/ahtapot-chat" element={<ProductSingle />} />
                        <Route path="/work/metamax" element={<ProductSingle />} />
                        <Route path="/careers/:id" element={<CareerDetails />} />
                        <Route path="/careers/:id/application" element={<Join />} />
                        <Route path="*" element={<Navigate to="/" replace />} />
                    </Routes>
                </ScrollToTop>
                <CookieBanner />
            </AnimatePresence></>
    );
}

export default App;
