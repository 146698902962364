import { Text, VStack } from "@chakra-ui/react";
import React from "react";

type Props = {
    title: string;
    description: string;
};

export default function VisionCard({ title, description }: Props): JSX.Element {
    return (
        <VStack align="flex-start" px={{ base: 4 }}>
            <Text fontWeight="semibold" fontSize="md">
                {title}
            </Text>
            <Text fontSize="sm">{description}</Text>
        </VStack>
    );
}
