import { Box, Flex, HStack, Text } from "@chakra-ui/react";
import React from "react";
import { FaStarOfLife } from "react-icons/fa";

type CompanyDefinitionProps = {
    subtitle: string;
    desc: string;
};
export default function CompanyDefinition({ subtitle, desc }: CompanyDefinitionProps): JSX.Element {
    return (
        <Flex direction="column" gap={{ base: "3", md: "3" }} color="primary.100" pl="3">
            <HStack justify="flex-start" align="center">
                <Box fontSize="md">
                    <FaStarOfLife />
                </Box>
                <Text fontWeight="semibold" fontSize="lg" color="primary.50">
                    {subtitle}
                </Text>
            </HStack>
            <Text>{desc}</Text>
        </Flex>
    );
}
