import { Heading, Text, VStack } from "@chakra-ui/react";
import React from "react";

export default function VisionTitle(): JSX.Element {
    return (
        <VStack align="flex-start" spacing="4">
            <Heading size="2xl" as="h3" fontWeight="light">
                Software alone is
                <Text as="span" fontWeight="bold">
                    {" "}
                    nothing
                </Text>
            </Heading>

            <Text fontSize="sm" fontWeight="light" maxW="530px">
                The codes are meaningful because of people. We owe everything to the people behind our screens.
            </Text>
        </VStack>
    );
}
