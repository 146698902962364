import { Text, VStack } from "@chakra-ui/react";
import React from "react";

export default function TechnologyTitle(): JSX.Element {
    return (
        <VStack>
            <Text w="250px" textAlign="center">
                We specialise in developing and designing
            </Text>
            <Text fontWeight="light" fontSize="3xl">
                Experiences using
            </Text>
        </VStack>
    );
}
