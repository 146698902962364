import { Box, Heading, SimpleGrid } from "@chakra-ui/react";
import React from "react";

import CompanyDefinition from "./CompanyDefinition";
import { Culture, Opportunity, Responsibility } from "./Data";

export default function Company(): JSX.Element {
    return (
        <Box as="section" py="12" px={{ base: "6", md: "8" }}>
            <Heading as="h3" fontWeight="semibold" fontSize="2xl" pb="8" pt="14">
                Opportunity
            </Heading>
            <SimpleGrid columns={{ base: 1, md: 2 }} spacingX="10" spacingY={{ base: "8", md: "14" }}>
                {Opportunity.map((d, index: React.Key) => (
                    <CompanyDefinition key={index} subtitle={d.subtitle} desc={d.desc} />
                ))}
            </SimpleGrid>
            <Heading as="h3" fontWeight="semibold" fontSize="2xl" py="8" pt="16">
                Responsibility
            </Heading>
            <SimpleGrid columns={{ base: 1, md: 2 }} spacingX="10" spacingY={{ base: "8", md: "14" }}>
                {Responsibility.map((d, index: React.Key) => (
                    <CompanyDefinition key={index} subtitle={d.subtitle} desc={d.desc} />
                ))}
            </SimpleGrid>
            <Heading as="h3" fontWeight="semibold" fontSize="2xl" pb="8" pt="16">
                Culture
            </Heading>
            <SimpleGrid columns={{ base: 1, md: 2 }} spacingX="10" spacingY={{ base: "8", md: "14" }}>
                {Culture.map((d, index: React.Key) => (
                    <CompanyDefinition key={index} subtitle={d.subtitle} desc={d.desc} />
                ))}
            </SimpleGrid>
        </Box>
    );
}
