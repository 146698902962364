import { Box, Flex, Stack, Text } from "@chakra-ui/react";
import React from "react";

export default function AboutHero(): JSX.Element {
    return (
        <Box
            px={{
                base: 4,
            }}
            py={{
                base: "24",
                md: "36",
            }}
        >
            <Flex h="full" gap="10" direction={{ base: "column", md: "row" }} align="center">
                <Stack w="full" h="full" spacing="2" alignItems="flex-start">
                    <Text fontSize="xs" fontWeight="semibold" py="1">
                        ABOUT US
                    </Text>
                    <Text fontSize="4xl" fontWeight="light">
                        R&D Offers{" "}
                        <Box as="span" fontWeight="semibold">
                            Productivity
                        </Box>{" "}
                        and Product Differentiation.
                    </Text>
                </Stack>
                <Stack
                    w="full"
                    h="full"
                    spacing="4"
                    alignItems="flex-start"
                    bg="golden"
                    fontSize={{ base: "sm", sm: "md", md: "lg" }}
                >
                    <Text>
                        R&D is very significant for businesses because it provides powerful knowledge. It also allows to
                        increase efficiency and reduce costs. So it achieve businesses to develop new products and
                        services develop in competitive markets.
                    </Text>
                    <Text>
                        We carry out successful software projects by producing decently in the vast world of software,
                        conducting R&D studies on the spot with collaborative solutions.
                    </Text>
                </Stack>
            </Flex>
        </Box>
    );
}
