import {Box, Container, Stack} from "@chakra-ui/react";

import {Members, MotionBox, Technologies} from "../../components";
import AboutGrid from "./AboutGrid";
import AboutHero from "./AboutHero";
import Features from "./features/Features";
import Visions from "./vision/Visions";
import React from "react";

export default function About(): JSX.Element {
    return (
        <Box as="section" bg="gray.700" id="about" className="section">
            <MotionBox bg="gray.700">
                <Container
                    minHeight="100vh"
                    height="100%"
                    px={{
                        md: "8",
                    }}
                    py={{
                        base: "12",
                        md: "16",
                    }}
                    maxW="container.xl"
                    color="white">
                    <AboutHero />
                    <Stack
                        bgGradient="linear(to-b, primary.500, secondary.500)"
                        px={{ base: "4", md: "8" }}
                        py={{ base: "4", md: "16" }}
                        borderRadius={{ base: "none", md: "md" }}
                        spacing={{ base: 16, md: 48 }}>
                        <Technologies />
                        <AboutGrid />
                        <Features />
                        <Visions />
                        <Members />
                    </Stack>
               {/*     <Scroll href="work" />*/}
                </Container>
            </MotionBox>
        </Box>
    );
}
