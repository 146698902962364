import { Box, Container, Text, VStack } from "@chakra-ui/react";
import React from "react";

export default function HomeHero(): JSX.Element {
    return (
        <Box as="section" w="full" h="full" color="white" alignItems="center" d="flex">
            <Container maxW={{ base: "xl", md: "4xl" }} px={{ base: "6", md: "8" }}>
                <VStack>
                    <Text textAlign="center" fontSize={{ base: "3xl", md: "4xl", lg: "5xl", xl: "6xl" }}>
                        Say hello to the vast world of software with
                        <Box as="span" fontWeight="extrabold">
                            {" "}
                            Orema!
                        </Box>
                    </Text>
                </VStack>
            </Container>
        </Box>
    );
}
