import { Box, Text, VStack } from "@chakra-ui/react";
import Confetti from "react-dom-confetti";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import React from "react";

const configs: any = {
    angle: "90",
    spread: "90",
    startVelocity: "30",
    elementCount: 200,
    dragFriction: 0.1,
    duration: 2000,
    stagger: 3,
    width: "4px",
    height: "4px",
    perspective: "500px",
    colors: ["#e890bd", "#d4d4d4", "#FC3F5F"],
};

const mouseStyle = {
    width: "30px",
    height: "30px",
    borderRadius: "0.5rem",
};

export default function ConfettiScroll(): JSX.Element {
    const [heartIcon, setHeartIcon] = React.useState(false);
    return (
        <VStack
            py={{ base: "8", md: "16" }}
            mx="0"
            bottom="0"
            left="0"
            right="0"
            position="relative"
            color="white"
            cursor="pointer"
            transition="all 0.5s ease"
            onClick={() => setHeartIcon(!heartIcon)}
        >
            <Box cursor="pointer" style={mouseStyle}>
                <Confetti active={heartIcon} config={configs} />
            </Box>
            {heartIcon ? (
                <AiFillHeart className="icon" size="24" color="#FC3F5F" cursor="pointer" />
            ) : (
                <AiOutlineHeart size="24" cursor="pointer" />
            )}
            <Text>You're done!</Text>
        </VStack>
    );
}
