import { Img, Stack, Text } from "@chakra-ui/react";
import React from "react";

type MemberProps = {
    name: string;
    title: string;
    image: string;
};
export default function Member({ name, title, image }: MemberProps): JSX.Element {
    return (
        <Stack key={name} spacing="4">
            <Stack spacing="2">
                <Img
                    src={image}
                    alt={name}
                    h={{ base: "60", md: "96" }}
                    objectPosition="top"
                    objectFit="cover"
                    borderRadius="md"
                />
                <Stack spacing="0">
                    <Text fontWeight="medium" fontSize={{ base: "md", md: "lg" }}>
                        {name}
                    </Text>
                    <Text color="accent" fontWeight="light" fontSize={{ base: "xs", md: "sm" }}>
                        {title}
                    </Text>
                </Stack>
            </Stack>
        </Stack>
    );
}
