import { HStack, Image, Text } from "@chakra-ui/react";
import React from "react";

import UkraineFlag from "../../assets/images/flags/ukraine.svg";

export default function Hero(): JSX.Element {
    return (
        <HStack
            zIndex="9"
            position="absolute"
            spacing="3"
            p="3"
            bottom="5"
            right="5"
            bg="primary.1000"
            borderRadius="full"
            display={{ base: "none", md: "flex" }}
        >
            <Image src={UkraineFlag} alt="Ukraine flag" />
            <Text fontSize="sm" color="white">
                #stopwarinukraine
            </Text>
        </HStack>
    );
}
