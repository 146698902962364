import { useEffect, useState } from "react";
import { Box, Button, Slide, Text, Stack, HStack, CloseButton, Img, Container, Link, useDisclosure } from "@chakra-ui/react";
import cookieIcon from '../../assets/images/cookie-icon.svg'
import CookieModal from "./CookieModal";

export default function CookieBanner() {
    const [isShow, setIsShow] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();

    useEffect(() => {
        const cookiePermission = localStorage.getItem("cookiePermission");
        if (cookiePermission === null) {
            const timeout = setTimeout(() => {
                setIsShow(true);
            }, 2000);

            return () => clearTimeout(timeout);
        }
    }, []);

    const acceptCookies = () => {
        localStorage.setItem("cookiePermission", "true");
        setIsShow(false);
    };

    const declineCookies = () => {
        localStorage.setItem("cookiePermission", "false");
        setIsShow(false);
    };

    if (!isShow) {
        return null;
    }

    return (
        <>
            <Box position="fixed" bottom="0" left="0" right="0" zIndex="1000">
                <Slide direction="bottom" in={isShow}>
                    <Box
                        bg="rgba(215, 118, 188, 0.5)"
                        color="white"
                        textAlign="center"
                        padding="10px"
                        position="relative"
                        backdropFilter="blur(20px)"
                        p="4"
                    >
                        <CloseButton _focus={{ border: "0" }} onClick={declineCookies} m="1" top="0" right="0" position="absolute" size="md" />
                        <Container maxW="container.xl">
                            <Stack gap="2" alignItems="center" justifyContent="space-between" direction={{ base: "column", sm: "row" }}>
                                <HStack alignItems="center" gap="2">
                                    <Img alt="Cookie Icon" boxSize="48px" src={cookieIcon} />
                                    <Text>This website uses cookies to enhance the user experience.{' '}
                                        <Link fontWeight="bold" onClick={onOpen} color='#391E5C'>
                                            Cookie Policy
                                        </Link>
                                    </Text>
                                </HStack>
                                <Stack w={{ base: "full", md: "unset" }} direction={{ base: "column", sm: "row" }} gap="2">
                                    <Button variant="secondary" onClick={declineCookies}>
                                        Decline
                                    </Button>
                                    <Button variant="primary" onClick={acceptCookies}>
                                        Accept
                                    </Button>
                                </Stack>
                            </Stack>
                        </Container>
                    </Box>
                </Slide>
            </Box>
            <CookieModal acceptCookies={acceptCookies} declineCookies={declineCookies} isOpen={isOpen} onClose={onClose} />
        </>
    );
}