import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
    fonts: {
        heading: "Inter, sans-serif",
        body: "Inter, sans-serif",
    },
    components: {
        CloseButton: {
            baseStyle: {
                _focus: {
                    border: "0",
                },
            },
        },
        Checkbox: {
            baseStyle: {
                control: {
                    _focus: {
                        boxShadow: "none",
                        borderColor: "purple.900",
                    },
                    _focusVisible: {
                        boxShadow: "none",
                        borderColor: "purple.900",
                    },
                    _selected: {
                        bgColor: "purple.900",
                    },
                    _checked: {
                        bgColor: "purple.900",
                        boxShadow: "none",
                        borderColor: "purple.900",
                        _hover: {
                            borderColor: "purple.900",
                            bgColor: "purple.900",
                        },
                    },
                },
            },
        },
        Button: {
            baseStyle: {
                _focus: {
                    borderColor: "none",
                },
            },
            variants: {
                'primary': {
                    bg: "#391E5C",
                    _hover: { bg: "#281541" },
                    _active: { bg: "#281541" },
                    _focus: { bg: "#281541" },
                    color: "white",
                },
                'secondary': {
                    border: "1px",
                    borderColor: "gray.200",
                    fontWeight: "sm",
                    _hover: { bg: "#ac5e96" },
                    _active: { bg: "#ac5e96" },
                    _focus: { bg: "#ac5e96" },
                },
            },
        },
    },
    colors: {
        primary: {
            50: "#DEE2F8",
            100: "#BDC6F1",
            200: "#9DA9EA",
            300: "#7C8CE3",
            400: "#5B70DC",
            500: "#3A53D5",
            600: "#3045B2",
            700: "#293B9A",
            800: "#27378E",
            900: "#1D2A6B",
            1000: "#2D374852",
        },
        secondary: {
            50: "#E7DEF8",
            100: "#CFBDF1",
            200: "#B79DEA",
            300: "#9E7CE3",
            400: "#865BDC",
            500: "#6E3AD5",
            600: "#5C30B2",
            700: "#49278E",
            800: "#371D6B",
            900: "#251347",
        },
        graySpecial: {
            50: "#F7FAFC",
            100: "#EDF2F7",
            200: "#E2E8F0",
            300: "#CBD5E0",
            400: "#A0AEC0",
            500: "#718096",
            600: "#4A5568",
            700: "rgba(2,211,116,0.52)",
            800: "#1A202C",
            900: "#171923",
        },
        fuschia: {
            50: "#e890bd",
            100: "#EF5DA8",
            500: "#FC3F5F",
            600: "#EC3E5E",
            900: "#2D374814",
        },
    },
});

export default theme;
