import { Box, Button, Container, Flex, HStack, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import { Scroll, Share } from "../../components";
import JobPostData from "../../components/job-post/JobPostData";

export default function CareerDetails(): JSX.Element {
    const { id } = useParams();
    const [post, setPost] = React.useState<any>();

    function getPost() {
        const newPost = JobPostData.find((p) => p.id === id);
        setPost(newPost);
    }

    useEffect(() => {
        getPost();

        //eslint-disable-next-line
    }, [id]);
    return (
        <Box bgGradient="linear(to-b, primary.500, secondary.500)" pb="12">
            <Box
                color="white"
                px={{ base: "2", md: "12", lg: "16", xl: "24" }}
                pt={{ base: "32", md: "40" }}
                position="relative"
            >
                <Container maxW="container.xl">
                    <Flex pb="6">
                        <Text fontWeight="semibold" color="primary.50">
                            Job openings {">"}
                        </Text>
                        <Text fontWeight="semibold" pl="2">
                            {post?.title}
                        </Text>
                    </Flex>

                    <Flex justify="space-between">
                        <Text fontSize="2xl">{post?.title}</Text>
                        <HStack spacing="8">
                            <Share />

                            <Link to={`/careers/${id}/application`}>
                                <Button
                                    position="relative"
                                    d={{ base: "none", md: " block" }}
                                    bg="fuschia.100"
                                    _hover={{ bg: "fuschia.50" }}
                                >
                                    Apply for this Job
                                </Button>
                            </Link>
                        </HStack>
                    </Flex>
                    <Flex direction="column" gap="12" pt="12">
                        <Box px="2">
                            <Text fontSize="lg" fontWeight="semibold">
                                Job description
                            </Text>
                            <Flex direction="column" fontSize="sm" gap="2">
                                {post?.description.map((d: string, index: React.Key) => (
                                    <Text key={index}>{d}</Text>
                                ))}
                            </Flex>
                        </Box>
                        <Box px="2">
                            <Text fontSize="lg" fontWeight="semibold">
                                Requirements
                            </Text>
                            <Flex direction="column" fontSize="sm" gap="2" pt="1">
                                <UnorderedList pl="1">
                                    {post?.requirements.map((r: string, index: React.Key) => (
                                        <ListItem key={index}>{r}</ListItem>
                                    ))}
                                </UnorderedList>
                            </Flex>
                        </Box>
                        <Box px="2">
                            <Text fontSize="lg" fontWeight="semibold">
                                Tools & Tech Stack:
                            </Text>
                            <Flex direction="column" fontSize="sm" gap="2" pt="1">
                                {post?.techs.map((t: string, index: React.Key) => (
                                    <Text key={index}>{t}</Text>
                                ))}
                            </Flex>
                        </Box>
                    </Flex>
                    <Box px="2" py="24">
                        <Link to={`/careers/${id}/application`}>
                            <Button w="100%" bg="fuschia.100" _hover={{ bg: "fuschia.50" }} borderRadius="md">
                                Apply for this Job
                            </Button>
                        </Link>
                    </Box>
                    <Scroll href="/careers" />
                </Container>
            </Box>
        </Box>
    );
}
