import { Box, Img } from "@chakra-ui/react";
import { motion } from "framer-motion";
import React from "react";

import { Hashtag, MotionBox, Scroll } from "../../components";
import HomeHero from "./HomeHero";
import mountain from "../../assets/images/mountain.svg";
import stars from "../../assets/images/stars.svg";

export default function Home(): JSX.Element {
    return (
        <Box height="100%" id="home" >
            <motion.div
                style={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    placeItems: "center",
                    placeContent: "center",
                }}
                animate={{
                    background: ["#3A53D5", "#6E3AD5", "#49278E", "#EF5DA8", "#FC3F5F", "#2D3748"],
                }}
                transition={{
                    duration: 20,
                    repeat: Infinity,
                    repeatType: "reverse",
                }}
            >
                <Box w="full" position="absolute" zIndex="1" bottom={-1}>
                    <Img position="absolute" bottom="-1" w="full" src={mountain} />
                    <Img zIndex="1" w="full"  h="100vh" src={stars} />
                </Box>
                <MotionBox zIndex="2">
                    <HomeHero />
                    <Hashtag />
                    <Scroll position="absolute" href="about" />
                </MotionBox>
            </motion.div>
        </Box>
    );
}
