import { useEffect } from "react";
import { useLocation } from "react-router-dom";

type props = {
    children: JSX.Element;
};

export default function ScrollToTop({ children }: props): JSX.Element {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return children;
}
