import { Center, CenterProps, Flex, Text, VStack } from "@chakra-ui/react";
import React, { useState } from "react";
import { useDropzone } from "react-dropzone";

export default function Dropzone(props: CenterProps): JSX.Element {
    const [uploadImage, setUploadImage] = useState({});
    const { getRootProps, getInputProps } = useDropzone({
        accept: "image/jpg, image/jpeg, image/png",
        onDrop: (acceptedFile) => {
            setUploadImage(acceptedFile[0].name);
        },
        maxSize: 409600,
        maxFiles: 1,
        multiple: false,
    });
    console.log(uploadImage);
    return (
        <Center
            w="100%"
            h="150px"
            borderWidth="1px"
            border="1px dashed"
            borderRadius="md"
            px="6"
            py="4"
            {...props}
            {...getRootProps()}
        >
            <Flex>
                <Flex {...getRootProps()}>
                    <input {...getInputProps()} />

                    <VStack>
                        <Text>Upload a file or drag and drop here</Text>
                        <Text color="primary.100" fontSize="sm" fontWeight="light">
                            We accept PDF, DOC, DOCX, JPG and PNG files
                        </Text>
                    </VStack>
                </Flex>
            </Flex>
        </Center>
    );
}
