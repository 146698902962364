import React from "react";

import {About, Careers, Contact, Work} from "../index";
import Home from "./Home";

import "../../App.scss";


export default function Landing(): JSX.Element {
    return (
        <>
            <Home/>
            <About/>
            <Work/>
            <Careers/>
            <Contact/>
        </>
    );
}
