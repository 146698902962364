import { Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Checkbox, Box, Text } from '@chakra-ui/react';
import { useState } from 'react';

interface CookieModalProps {
    isOpen: boolean;
    onClose: () => void;
    acceptCookies: () => void;
    declineCookies: () => void;
}

const cookiePolicy = `
This cookie policy is valid for orema.com.tr, mobile platforms, third-party programs, or platforms accessed and used by Orema. Orema attaches importance to the protection of privacy and personal data to ensure that its users benefit from Orema’s services safely and completely, and takes all necessary precautions by keeping the system and internet infrastructure at the most reliable and up-to-date level to protect the confidentiality of personal information.

Purpose element: Cookies allow the display of personalized pages to provide a more convenient and faster experience for your personal needs as well as for a site to work more efficiently. Cookies only contain information about your visit history on the internet and do not collect any information about the files stored on your computer or mobile device.

Types of cookies: Cookies may appear in different types according to classification types. So-called session cookies refer to temporary cookies that are kept on your devices until you leave the website. Persistent cookies, on the other hand, are cookies that remain on your device’s hard drive for a long time.

Mandatory cookies ensure that the website can function properly and that users can use the site’s navigation and features. Mandatory cookies are anonymous.

Functional and analytical cookies contain data about remembering your preferences, using the website effectively, optimizing the site to respond to user requests, and how visitors use the site. Due to their nature, these types of cookies do not contain usernames, etc. may contain your personal information.

Commercial cookies, on the other hand, serve to increase your usage experience by presenting products/content similar to your targeted product/content in line with your interests and choices, and by offering a more advanced, personalized advertising portfolio.

The above-mentioned session, permanent, functional analytical, and commercial cookies are kept in the background for approximately 2 (two) months, and the necessary adjustments can be made in the personal internet browser settings. The removal process from these settings may vary based on the internet browser.

Third-party cookies: Orema websites/mobile applications/mobile websites may work with trusted and well-known third-party advertising providers. Third-party service providers place their own cookies to serve you specific advertisements. Cookies placed by third parties collect and process the browsing information of visitors on websites and analyze how they are used.

Cookie permissions: By using Orema websites/mobile applications/mobile websites, you consent to the use of cookies by the policy. If you do not want to accept the use of cookies on your electronic device, you can refuse the application of cookies. If you refuse cookies, our website or mobile applications may not work properly or there may be disruptions in the provision of the services you request.

Information about the use of cookies will be given in a pop-up window on the first visit to the Orema website/mobile applications/mobile websites. You will be deemed to have accepted when you close this window. As stated above, users can opt out of using cookies.

Cookie management: You can clear stored cookie data until you disable cookies. The location of these settings varies depending on the browser you are using.

You can find the location of the relevant settings by using the “help” function in your browser.

For more information about the cookie policy, you can contact the site by sending an e-mail to contact@orema.com.tr.

Orema may change the provisions of this cookie policy when it deems necessary. Any provisions that Orema makes amends are deemed to have taken effect from the date the policy was updated.
`.trim();

function CookieModal({ isOpen, onClose, acceptCookies, declineCookies }: CookieModalProps) {
    const [checked, setChecked] = useState(false)

    return (
        <Modal
            isCentered
            isOpen={isOpen}
            onClose={onClose}
        >
            <ModalOverlay />
            <ModalContent
                color="white"
                bg="rgba(215, 118, 188, 0.5)"
                backdropFilter="blur(20px)"
            >
                <ModalHeader>Orema Software Cookie Policy</ModalHeader>
                <ModalCloseButton _focus={{ border: "0" }} />
                <ModalBody px="6" fontSize="sm" fontWeight="normal">
                    <Box borderRadius="lg" p="4" border="2px solid" borderColor="#c16aa9" maxH={{ base: "220", sm: "160", lg: "320" }} overflowY="scroll" whiteSpace="pre-wrap">
                        <Text textAlign="justify" overflowX="unset" >
                            {cookiePolicy}
                        </Text>
                    </Box>
                    <Checkbox
                        isChecked={checked}
                        onChange={(e) => setChecked(e.target.checked)}
                        mt="2"
                    >
                        Agree
                    </Checkbox>
                </ModalBody>
                <ModalFooter>
                    <Button isDisabled={!checked} w="full" variant="primary" onClick={() => acceptCookies()}>
                        Accept
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal >
    );
}

export default CookieModal;