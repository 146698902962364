import React from "react";
import { Box, Container, Flex, Image, Link, ListItem, SimpleGrid, Text, UnorderedList, VStack } from "@chakra-ui/react";
import ProductImage from "../../assets/images/product-banners/ahtapot-chat-banner.png";
import {BackHome, Scroll} from "../../components";
import AhtapotChat from "../../assets/images/products/ahtapot-chat.svg";

export default function AhtapotChatPage(): JSX.Element {
    return (
        <Box bg="gray.700" color="white">
            <BackHome />
            <Container
                px={{ base: "6", md: "24" }}
                pb={{
                    base: "8",
                }}
                pt={{
                    base: "0",
                }}
                maxW="container.xl"
            >
                <VStack
                    py={{
                        base: "24",
                        md: "36",
                    }}
                >
                    <Link href="https://landing.ahtapot.chat/">
                        <Image color="white" w={{ base: "150px", md: "200px" }} src={AhtapotChat} alt="ahtapot.chat" />
                    </Link>
                    <Text fontSize="3xl" fontWeight="light" textAlign="center">
                        You can make video calls with your team or friends anywhere and at anytime
                    </Text>
                </VStack>

                <Flex
                    px={{ base: 4, md: 6 }}
                    py={{ base: 2, md: 4 }}
                    borderRadius="md"
                    bg="gray.600"
                    direction={{ base: "column", sm: "row", md: "row" }}
                    justify="space-between"
                    gap="4"
                >
                    <Flex direction="column">
                        <Text color="#FFFFFFA3">Industry</Text>
                        <Text>Video Conferencing </Text>
                    </Flex>

                    <Flex direction="column">
                        <Text color="#FFFFFFA3">Platforms</Text>
                        <Text>Web, Mobile, Desktop</Text>
                    </Flex>
                    <Flex direction="column">
                        <Text color="#FFFFFFA3">Technologies</Text>
                        <Text>React, React Native, Java, LUA</Text>
                    </Flex>
                    <Flex direction="column">
                        <Text color="#FFFFFFA3">Year</Text>
                        <Text>2019</Text>
                    </Flex>
                </Flex>
                <Image src={ProductImage} w="100%" pt="2" borderTopRadius="md" />
                <VStack
                    spacing="12"
                    bg="gray.600"
                    pt="2"
                    px={{ base: "4", sm: "6", md: "12", lg: "16", xl: "24" }}
                    pb="12"
                    fontWeight="light"
                    lineHeight="1.1"
                    borderBottomRadius="md"
                >
                    <VStack direction="column" mt="10" gap="2" align="flex-start" fontSize="sm">
                        <Text color="primary.100" fontSize="md" lineHeight="1.5">
                            Ahtapot.chat is easy to install and use. It provides unlimited usage opportunities such as
                            business-related online meetings, customer service support, distance learning, remote
                            inspection, video conferencing or chatting with friends, family.
                        </Text>
                    </VStack>

                    <VStack direction="column" mt="10" gap="2" align="flex-start">
                        <Text fontWeight="semibold" fontSize="md">
                            Easy-to-Use
                        </Text>
                        <Text color="primary.100" fontSize="md" lineHeight="1.5">
                            Thanks to Ahtapot.chat's user-friendly interfaces, many operations can be performed in one
                            or two steps. You can schedule calls via your own calendar application or other calendar
                            services (Google, Microsoft), invite people registered in your contacts applications via
                            email, sms or other notification services.
                        </Text>
                    </VStack>
                    <VStack direction="column" mt="10" gap="2" align="flex-start">
                        <Text fontWeight="semibold" fontSize="md">
                            Free
                        </Text>
                        <Text color="primary.100" fontSize="md" lineHeight="1.5">
                            Users can participate in voice and video calls from a web browser, desktop and mobile
                            applications without paying any price with Ahtapot.chat. Ahtapot.chat doesn't include any
                            ads or extra features sold in the app.
                        </Text>
                    </VStack>
                    <VStack direction="column" mt="10" gap="2" align="flex-start">
                        <Text fontWeight="semibold" fontSize="md">
                            Security and privacy
                        </Text>
                        <Text color="primary.100" fontSize="md" lineHeight="1.5">
                            Ahtapot.chat's main idea of existence is based on the confidentiality and security of
                            interviews. Recordings of conversations or shared files are not saved by Ahtapot.chat.
                            However, the user can register only on his/her own device if he/she wishes and with the
                            consent of other participants.
                        </Text>
                    </VStack>
                    <VStack direction="column" mt="10" gap="2" align="flex-start" w="100%">
                        <Text fontWeight="semibold" fontSize="md">
                            Main Features
                        </Text>
                        <UnorderedList pl="4">
                            <SimpleGrid
                                columns={2}
                                spacing="8"
                                color="primary.100"
                                lineHeight="1.7"
                                w="100%"
                                fontSize="sm"
                            >
                                <Box>
                                    <ListItem>HD Video & Audio conferencing</ListItem>
                                    <ListItem>Integrated with calendar applications</ListItem>
                                    <ListItem>End-to-end in cipher secure call</ListItem>
                                    <ListItem>Call recording</ListItem>
                                </Box>
                                <Box pl="2">
                                    <ListItem>Screen sharing</ListItem>
                                    <ListItem>Personal & Corporate workspace</ListItem>
                                    <ListItem>Messaging and file sharing</ListItem>
                                </Box>
                            </SimpleGrid>
                        </UnorderedList>
                    </VStack>
                </VStack>
            </Container>

            <Scroll href="/#work" status={false} />
        </Box>
    );
}
