import {Box, Container} from "@chakra-ui/react";
import React from "react";

import {MotionBox, Products, TwoGridHero} from "../../components";

export default function Work(): JSX.Element {
    return (
        <Box as="section" bg="primary.500" id="work" className="section">
            <MotionBox>
                <Container
                    minHeight="100vh"
                    height="100%"
                    px={{
                        base: "4",
                        md: "8",
                    }}
                    pt={{
                        base: "12",
                        md: "16",
                    }}
                    maxW="container.xl"
                    color="white">
                    <TwoGridHero
                        title="WORK"
                        subtitle="We know the pain points on developing digital products."
                        desc="Different by software development, we partner in your success with strong communication solutions as
                    you invest in a future that you can control."
                    />
                    <Products />
                   {/* <Scroll href="careers" />*/}
                </Container>
            </MotionBox>
        </Box>
    );
}
